import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { msalConfig } from "./authConfig.js";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider} from '@azure/msal-react';
import { useNavigate } from "react-router-dom";
import UnsecuredPage from './UnsecuredPage.js';
import { datadogRum } from '@datadog/browser-rum';

/**
* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/

// Hiding console logs, errors and warnings
  console.log = (...args) => {
    return;
  };

  console.warn = (...args) => {
    return;
  };

  console.error = (...args) => {
    return;
  };

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

let isCusLoggedIn;

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {

    localStorage.setItem("token_type", event.payload.tokenType);
    localStorage.setItem("access_token", event.payload.accessToken);
    localStorage.setItem("curentRole", event.payload.account.idTokenClaims.currentRole);
    localStorage.setItem("profile_initial_credential",  event.payload.account.idTokenClaims.given_name.split("")[0] + event.payload.account.idTokenClaims.family_name.split("")[0]);
    localStorage.setItem("profile_name",  event.payload.account.idTokenClaims.given_name + " " + event.payload.account.idTokenClaims.family_name);
    localStorage.setItem("role_subaccount", (event.payload.account.idTokenClaims.department || event.payload.account.idTokenClaims.currentRole.toLowerCase().includes("cloud admin view")) ? true : false);
    localStorage.setItem("user_permissions", event.payload.account.idTokenClaims.userPermissions ? event.payload.account.idTokenClaims.userPermissions : ("[{\"page\":\"My Synnex\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Provision\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Manage\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Billing\",\"isReadWriteAccess\":false,\"isReadOnlyAccess\":false,\"disabled\":true},{\"page\":\"Customers\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Integrations\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Order History\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Prerequisite\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Profile\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false}]"));
    // localStorage.setItem("user_permissions", "[{\"page\":\"My Synnex\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Provision\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Manage\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Billing\",\"isReadWriteAccess\":false,\"isReadOnlyAccess\":false,\"disabled\":true},{\"page\":\"Customers\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Integrations\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Order History\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Prerequisite\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false},{\"page\":\"Profile\",\"isReadWriteAccess\":true,\"isReadOnlyAccess\":false,\"disabled\":false}]");
    // console.log("Logged in User Details :", event.payload);
    msalInstance.setActiveAccount(event.payload.account);
  }
});

datadogRum.init({
    applicationId: '5f8f8e61-d7f8-4bf7-b548-70bb7516cbb6',
    clientToken: 'pub5780c08afbfcbe90e001971ad6c6e425',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'us5.datadoghq.com',
    service: 'ncm-pord',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
    {window.self === window.top ? <App instance={msalInstance} isCusLoggedIn = {isCusLoggedIn}/> : <UnsecuredPage />}
      {/* <App instance={msalInstance} isCusLoggedIn = {isCusLoggedIn} /> */}
    </MsalProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
