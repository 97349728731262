import React, { useState, useEffect, useRef } from 'react'
import { Col, Container, Row, Button, Form, InputGroup, Dropdown, NavDropdown } from 'react-bootstrap'
import styles from './agreementMapping.module.scss';
import { GET_AGREEMENT_MAPPING, DOWNLOAD_AGREEMENT_REPORT, GET_SYNCHRONIZATION_DATA, AGREEMENT_MAPPING_PLANS, AGREEMENT_MAPPING_BILLING_TERM, AGREEMENT_MAPPING_COMPANY, AGREEMENT_MAPPING_SERVICES, GET_CUSTOMERS_LIST, AGREEMENT_MAPPING_AGREEMENT } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import Toast from '../../../../../../../GlobalComponents/Toast';
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import Map from './LocalComponents/Map';
import Unmap from './LocalComponents/Unmap';
import Edit from './LocalComponents/Edit';
import { createTheme } from 'react-data-table-component';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import BulkMapping from './LocalComponents/BulkMapping';
import AgreementMappingFilterBox from './LocalComponents/FilterBox';
import LazyTable from './LocalComponents/LazyTable';

const AgreementMappingConfigure = ({ setActiveStep, Logout }) => {

  const date = new Date();                                                            // for js date time function
  const [searchValueTable, setSearchValueTable] = useState(null);                     // storing searching value in the search input box
  const [searchIconFlag, setSearchIconFlag] = useState(false);                        // to open and close search box
  const searchInputRef = useRef();                                                    // Search Input Box
  const [filterFlag, setFilterFlag] = useState(false);                                // to show active and deactive filter states 
  const [showFilterBox, setShowFilterBox] = useState(false);                          // showing filter box
  let ColumnName = ["Customer", "Service", "Plan Name", "Billing Term", "Company",
    "Agreement Name", "Addition Name", "Addition Description", "Action"];               // Columns of table 
  const [pageNo, setPageNo] = useState(1);                                            // table active page number
  const [pageSize, setPageSize] = useState(15);                                       // table active page size
  const [totalRows, setTotalRows] = useState(0);                                      // table total count of data from api 
  const [columns, setColumns] = useState([]);                                         // columns used to display column headers in the react data table component
  const [table, setTable] = useState([]);                                             // data state used to display data in react data table component

  const [planName, setPlanName] = useState("");                                       // storing data for agreement mapping table data-plan name
  const [serviceId, setServiceId] = useState("");                                     // storing data for agreement mapping table data-service id
  const [customerId, setCustomerId] = useState("");                                   // storing data for agreement mapping table data-customer id
  const [companyId, setCompanyId] = useState("");                                     // storing data for agreement mapping table data-company id
  const [agreementId, setAgreementId] = useState("");                                 // storing data for agreement mapping table data-agreement id
  const [billingPeriodId, setBillingPeriodId] = useState("");                         // storing data for agreement mapping table data-billing peroid id
  const [selectedOption, setSelectedOption] = useState("Unmapped Subscriptions");     // option selected from dropdown
  const [emptySearch, setEmptySearch] = useState(false);                             // empty search response check state in table during api call to load data
  const [emptyData, setEmptyData] = useState(false);                                 // empty search response check state in table during api call to load data

  const [emptyFilter, setEmptyFilter] = useState(false);                             // empty filter response check state in table during api call to load data
  const [serverError, setServerError] = useState(false);                             // server error check state in table during api call to load data
  const [mapFilter, setMapFilter] = useState("0");                                   // storing map filter id
  const [mapActionPopup, setMapActionPopup] = useState(false);                       // opening first step of map action 
  const [completeRowData, setCompleteRowData] = useState("");                        // data to transfer in map action
  const [unMapActionPopup, setUnMapActionPopup] = useState(false);                   // opening first step of unmap action 
  const [uploadMappingDone, setUploadMappingDone] = useState(false);                 // After uploading done, next pop up will be load-bulk mapping
  const [uploadModalOpen, setUploadModalOpen] = useState(false);                     // bulk upload popup
  const [editActionPopup, setEditActionPopup] = useState(false);                     // opening edit action in mapped filter data 
  const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);               // loading state for processing button
  const [actionCompletedRefreshTable, setActionCompletedRefreshTable] =              // refresh table when action is performed
    useState(false)

  const [syncronizationData, setSyncronizationData] = useState("");                  // for storing data from api in syncronization settings
  const integrationId = "1";                                                         // integration id paased
  const [loadingState, setLoadingState] = useState(false);                           // loading state while api is called

  //filters
  const [customerData, setCustomerData] = useState(null);
  const [planData, setPlanData] = useState(null);
  const [serviceData, setServiceData] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [billingTermData, setBillingTermData] = useState(null);
  const [agreementData, setAgreementData] = useState(null);

  // options displayed for mapfilter selection in dropdown
  const subscriptionsOptions = {
    "0": "Unmapped Subscriptions",
    "1": "Mapped Subscriptions",
    "-1": "All Subscriptions"
  };

  // Use State for Toasts
  const [toastError, setToastError] = useState(false);                            // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                           // toast message usestate

  //lazy loading
  const [scrollToDivTop, setScrollToDivTop] = useState(false);
  const [infinityLoading, setInfinityLoading] = useState(false);                     // loading state while table loads for lazy loading

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Header Authorization for Download PDF API having response type as blob
  const configBlob = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
    responseType: 'blob',
  };

  const handleSelect = (eventKey) => {
    setSelectedOption(subscriptionsOptions[eventKey]);
    setMapFilter(eventKey);
  };

  // Download File using Js blobs
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    if (fileType === "application/pdf") {
      a.href = window.URL.createObjectURL(data)
    }
    else {
      a.href = window.URL.createObjectURL(blob)
    }
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  // Download PDF pricing
  async function DownloadAction(url, name, fileType, pageNo, pageSize, searchValueTable, customerId, planName, serviceId, billingPeriodId, mapFilter, companyId, agreementId) {
    setConfirmLoadingFlag(true);
    (searchValueTable === undefined || searchValueTable === null) ? searchValueTable = "" : searchValueTable = searchValueTable;
    (customerId === undefined || customerId === null) ? customerId = "" : customerId = customerId;
    (planName === undefined || planName === null) ? planName = "" : planName = planName;
    (serviceId === undefined || serviceId === null) ? serviceId = "" : serviceId = serviceId;
    (billingPeriodId === undefined || billingPeriodId === null) ? billingPeriodId = "" : billingPeriodId = billingPeriodId;
    (mapFilter === undefined || mapFilter === null) ? mapFilter = "" : mapFilter = mapFilter;
    (companyId === undefined || companyId === null) ? companyId = "" : companyId = companyId;
    (agreementId === undefined || agreementId === null) ? agreementId = "" : agreementId = agreementId;

    let configuration;
    if (fileType === "pdf" || fileType === "zip") {
      configuration = configBlob;
    }
    else {
      configuration = config;
    }
    await api.get(`${url}?customerId=${customerId}&planName=${planName}&serviceIds=${serviceId}&billingPeriodId=${billingPeriodId}&mapFilter=${mapFilter}&pageNo=${pageNo}&pageSize=${pageSize}&searchText=${searchValueTable}&companyId=${companyId}&agreementId=${agreementId}`, configuration)
      .then(resp => {
        // handle success
        if (resp.status === 204) {
          setConfirmLoadingFlag(false);
          setToastMessage("No Data available to Download !");
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 2000);
        }
        else {
          setConfirmLoadingFlag(false);
          downloadFile({
            data: (resp.data),
            fileName: name,
            fileType: `application/${fileType}`,
          });
        }
      })
      .catch(error => {
        setConfirmLoadingFlag(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  // Three Dot Actions Functionality
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      className={styles['threeDotAnchorTag']}
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className={styles["threedots"]} />
    </a>
  ));

  // Get Table Data with Filters, Pagination, Search Functionality
  async function GetTableData(page, newPerPage, search, customerId, planName, serviceId, billingPeriodId, mapFilter, companyId, agreementId, appendData, isFilter) {
    setLoadingState(true);
    (search === undefined || search === null) ? search = "" : search = search;
    (customerId === undefined || customerId === null) ? customerId = "" : customerId = customerId;
    (planName === undefined || planName === null) ? planName = "" : planName = planName;
    (serviceId === undefined || serviceId === null) ? serviceId = "" : serviceId = serviceId;
    (billingPeriodId === undefined || billingPeriodId === null) ? billingPeriodId = "" : billingPeriodId = billingPeriodId;
    (mapFilter === undefined || mapFilter === null) ? mapFilter = "" : mapFilter = mapFilter;
    (companyId === undefined || companyId === null) ? companyId = "" : companyId = companyId;
    (agreementId === undefined || agreementId === null) ? agreementId = "" : agreementId = agreementId;
    isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

    await api.get(`${GET_AGREEMENT_MAPPING}?customerId=${customerId}&planName=${planName}&serviceIds=${serviceId}&billingPeriodId=${billingPeriodId}&mapFilter=${mapFilter}&pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&companyId=${companyId}&agreementId=${agreementId}`, config)
      .then(resp => {
        setLoadingState(false);
        setInfinityLoading(false);

        if (resp.status == 204) {
          if (search === "" && customerId === "" && planName === "" && serviceId === "" && billingPeriodId === "" && companyId === "" && agreementId === "") {
            setEmptyData(true);
          }
          else if ((customerId != "" || planName != "" || serviceId != "" || billingPeriodId != "" || companyId != "" || agreementId != "") && isFilter) {
            setEmptyFilter(true);
          }
          else if (search != "" && !isFilter) {
            setEmptySearch(true);
          }

        }
        // handle success
        else {
          setEmptySearch(false);
          setEmptyData(false);
          setEmptyFilter(false);
          setServerError(false);
          let f = [];
          setTotalRows(resp.data.page.totalElements);
          (resp.data.content).map((td) => {
            f.push
              (
                {
                  "Customer": td.customerName,
                  "Mapping": td.isMapped,
                  "Service": td.serviceName,
                  "Plan Name": td.planName,
                  "Billing Term": td.billingTerm,
                  "Company": td.companyName === null ? "-" : td.companyName,
                  "Agreement Name": td.connectwiseCustomerAgreementName === null ? "-" : td.connectwiseCustomerAgreementName,
                  "Addition Name": td.connectwiseCustomerAgreementAdditionName === null ? "-" : td.connectwiseCustomerAgreementAdditionName,
                  "Addition Description": td.connectwiseAdditionDescription === null ? "-" : td.connectwiseAdditionDescription,
                  "Action": <Dropdown drop={"start"}>
                    <Dropdown.Toggle as={CustomToggle} />
                    <Dropdown.Menu size="sm" title="">
                      {
                        (td.isMapped) ? (
                          <>
                            <Dropdown.Item onClick={() => {
                              setEditActionPopup(true);
                              setCompleteRowData(td);
                            }} >Edit Mapping </Dropdown.Item>

                            <Dropdown.Item onClick={() => {
                              setUnMapActionPopup(true);
                              setCompleteRowData(td);
                            }} id="2">Unmap</Dropdown.Item>
                          </>
                        ) : (
                          <Dropdown.Item onClick={() => {
                            setMapActionPopup(true);
                            setCompleteRowData(td);
                          }} id="1">Map</Dropdown.Item>
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                }
              );
          })
          setTimeout(() => {
            if (appendData) {
              setTable(table => [...table, ...f])            // Setting Table Data
            } else {
              setTable(f)
            }
          }, 50);
          let d = [];
          ColumnName.map((td) => {
            if (td === "Action") {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                center: true,
                allowOverflow: true,
              })
            }
            else if (td === "Plan Name") {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 3,
              })
            }
            else if (td === "Service") {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 1.2,
              })
            }
            else if (td === "Company" || td === "Agreement Name") {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 1.2,
              })
            }
            else if (td === "Billing Term") {
              d.push({
                id: `${td}`,
                name: <div>{td}
                 </div>,
                selector: row => <div className="text-wrap">
                  {row[`${td}`]}</div>,
                left: true,
                grow: 1.5,
                allowOverflow: true,
              })
            }
            else {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
              })
            }

          })
          setColumns(d);       // Setting Column Data
        }
      })
      .catch(error => {
        setEmptyData(false);
        setLoadingState(false);
        setEmptySearch(false);
        setEmptyFilter(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
          setServerError(true);
        }
      });
  };

  // get api for agreement mapping data
  async function GetSyncronizationData() {
    await api.get(`${GET_SYNCHRONIZATION_DATA}${integrationId}`, config)
      .then(resp => {
        if (resp.status == 204) {
        }
        else {
          setSyncronizationData(resp.data);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  // Function to call all dropdown values in Customer Section in Filter Box
  async function GetAllCustomers() {
    await api.get(GET_CUSTOMERS_LIST, config)
      .then(resp => {
        setCustomerData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };


  // Function to call all dropdown values in Plan Section in Filter Box
  async function GetAllPlans() {
    await api.get(AGREEMENT_MAPPING_PLANS, config)
      .then(resp => {
        setPlanData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }

      });
  };

  // Function to call all dropdown values in Services Section in Filter Box
  async function GetAllServices() {
    await api.get(`${AGREEMENT_MAPPING_SERVICES}${integrationId}`, config)
      .then(resp => {
        setServiceData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  // Function to call all dropdown values in Company Section in Filter Box
  async function GetAllCompany() {
    await api.get(AGREEMENT_MAPPING_COMPANY, config)
      .then(resp => {
        setCompanyData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }

      });
  };

  // Function to call all dropdown values in billing term Section in Filter Box
  async function GetAllBillingTerm() {
    await api.get(AGREEMENT_MAPPING_BILLING_TERM, config)
      .then(resp => {
        setBillingTermData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  // Function to call all dropdown values in agreement Section in Filter Box
  async function GetAllAgreements() {
    await api.get(AGREEMENT_MAPPING_AGREEMENT, config)
      .then(resp => {
        setAgreementData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  // Function to handle the lazy loading calls invoked from the Lazy Table
  const handleLazyCalls = async () => {
    setInfinityLoading(true);
    setPageNo((prev) => {
      const newPageNumber = prev + 1;
      (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, customerId, planName, serviceId, billingPeriodId, mapFilter, companyId, agreementId, true))();

      return newPageNumber;
    });
  }

  // React Data Table Custom Theme
  createTheme('solarized', {
    text: {
      primary: '#000000',
    },
    background: {
      default: '#FFFFFF',
    },
    divider: {
      default: '#d7dadf',
    },
    striped: {
      default: '#f5f5f5',
    },
  });

  // React Data Table Custom Styles
  const customStyles = {
    tableWrapper: {
      style: {
        minHeight: '350px'
      },
    },
    rows: {
      style: {
        minHeight: '60px',          // override the row height
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',         // override the cell padding for data cells
        paddingRight: '8px',
        // textAlign: 'center',
        fontSize: '0.9rem',
        fontWeight: '400',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '10px',        // override the cell padding for head cells
        paddingRight: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#fafafa',
        fontWeight: '500',
        fontSize: '0.875rem',
        color: '#6A6A6A !important',
        textTransform: 'capitalize',
        // textAlign: 'center',
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: row => row["Mapping"] === true,
      style: {
        backgroundColor: '#EFF5FB',
      },
    },
  ];

  // Function Triggers when Page Number is Changed by Customer
  const handlePageChange = (pageNo) => {
    GetTableData(pageNo, pageSize, searchValueTable, customerId, planName, serviceId, billingPeriodId, mapFilter, companyId, agreementId)
    setPageNo(pageNo);
  };

  // Function Triggers when Rows Per Page is Changed by Customer
  const handlePerRowsChange = async (newPerPage, page) => {
    setPageNo(page);
    setPageSize(newPerPage);
    GetTableData(page, newPerPage, searchValueTable, customerId, planName, serviceId, billingPeriodId, mapFilter, companyId, agreementId)
  };

  // On search field value we trigger this function having react debugging after every 800 ms
  useEffect(() => {
    if (searchValueTable !== undefined && searchValueTable !== null) {
      const getData = setTimeout(() => {
        if (searchValueTable.length > 0) {
          setScrollToDivTop(true);
          setPageNo(1);
          GetTableData(1, pageSize, searchValueTable, customerId, planName, serviceId, billingPeriodId, mapFilter, companyId, agreementId)
        } else {
          setScrollToDivTop(true);
          setPageNo(1);
          GetTableData(1, pageSize, searchValueTable, customerId, planName, serviceId, billingPeriodId, mapFilter, companyId, agreementId)
        }
      }, 800);
      return () => clearTimeout(getData);
    }
  }, [searchValueTable])

  // Search Box Focus on Open
  useEffect(() => {
    searchInputRef.current && searchInputRef.current.focus();
  }, [searchIconFlag]);

  //when subscrition type is changed ie. unmapped, mapped or all
  useEffect(() => {
    setScrollToDivTop(true);
    setPageNo(1);
    GetTableData(1, pageSize, searchValueTable, customerId, planName, serviceId, billingPeriodId, mapFilter, companyId, agreementId)
  }, [mapFilter]);

  //useEffect for calling get api on initial load
  useEffect(() => {
    GetSyncronizationData();
    GetAllCustomers();
    GetAllBillingTerm();
    GetAllCompany();
    GetAllServices();
    GetAllPlans();
    GetAllAgreements();
  }, [])

  // Refreshing Subscription Table on Action Completion
  useEffect(() => {
    if (actionCompletedRefreshTable) {
      GetTableData(pageNo, pageSize, searchValueTable, customerId, planName, serviceId, billingPeriodId, mapFilter, companyId, agreementId)
      setActionCompletedRefreshTable(false);
    }
  }, [actionCompletedRefreshTable])

  return (
    <>
      <Popup
        open={uploadModalOpen}
        onClose={() => { setUploadModalOpen(false); setUploadMappingDone(false) }}
        className={`custom-modal ${uploadMappingDone == true ? "custom-modal-xl custom-modal-upload" : "custom-modal-md"} custom-modal-ticket-details`}
        modal
        nested
      >
        {(close) => (
          <div className={`modal ${uploadMappingDone == true ? "custom-modal-xl custom-modal-upload" : "custom-modal-md"}`}>
            <div className="header">
              <h2>Bulk Mapping - Confirmation</h2>
              <XLg
                size={24}
                className="cpointer close-icon-popup"
                onClick={close}
              />
            </div>
            <Container>
              <BulkMapping uploadMappingDone={uploadMappingDone} setUploadMappingDone={setUploadMappingDone} Logout={Logout} />
            </Container>
          </div>
        )}
      </Popup>


      <Container fluid>
        <Toast toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

        <div className={`ml-1 mr-1 details-box ${styles['cloud-summary-table-inner']} ${styles['table-details']}`}>
          <div>

            <Row className="my-0 table-heading align-items-center">
              <div className="col-lg-2 col-md-2">
                <h5 className="py-1 pl-l mb-0 mobile-padding-search-open subscription-heading-table-header">Plan {!emptyData && !emptyFilter && !emptySearch && !loadingState && <span className='text-muted text-large'>({totalRows})</span>}</h5>
              </div>



              {/* Destop */}
              <div class="px-0 d-none d-md-block col-lg-10 col-md-10">
                <div className='col-12'>
                  <div class="justify-content-xl-end justify-content-lg-between d-flex align-items-center">
                    <div class="pl-1">
                      {/* Filter Search Box for Desktop */}
                      <div class="justify-content-xl-end justify-content-lg-start align-items-center d-flex">

                        {!emptyData && !serverError && !emptyFilter &&
                          <InputGroup className="search-input-box search-input-box-lg">
                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                              <span className="searchgrey cpointer"
                              >
                                &nbsp;
                              </span>
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="Search Customer / Plan"
                              aria-label="Search"
                              aria-describedby="basic-addon2"
                              className="search-input-box-input"
                              value={searchValueTable}
                              onChange={(e) => setSearchValueTable(e.target.value)}
                              ref={searchInputRef}
                            />
                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                              onClick={() => {
                                setSearchValueTable("");
                                setSearchIconFlag(!searchIconFlag);
                              }}
                            >
                              <span className="closebtn">
                                &nbsp;
                              </span>
                            </InputGroup.Text>
                          </InputGroup>}

                        <div class="mr-1">
                          {!serverError && <div>
                            {!filterFlag && !emptySearch && !loadingState && <span className="filter-black cpointer d-block" id="filterblock" onClick={() => {
                              setShowFilterBox(!showFilterBox);
                            }}></span>}
                            {filterFlag && !emptySearch && !loadingState && <span className="filter-black-active cpointer d-block" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                          </div>}
                        </div>
                      </div>
                    </div>

                    {/* Unmapped Dropdown */}
                    <div class="pl-1">
                      <div>
                        {!serverError &&
                          <Form.Group>
                            <NavDropdown
                              title={selectedOption}
                              id="cloud-service-dropdown"
                              onSelect={handleSelect}
                              className={`servcies-dropdown ${styles['product-plan-top-right-card']}`}
                              value={selectedOption}
                            >
                              {Object.entries(subscriptionsOptions).map(([key, value]) => (
                                <NavDropdown.Item key={key}
                                  className={selectedOption === value ? "selected" : ""}
                                  eventKey={key}>{value}</NavDropdown.Item>
                              ))}
                            </NavDropdown>
                          </Form.Group>}
                      </div>
                    </div>

                    {/* Buttons */}
                    <div class={`${styles["btn-wrapper-outer"]}`}>
                      <div className="btn-wrapper text-right ml-1">
                        {!confirmLoadingFlag ? <Button
                          className={`px-2 mx-1 btn-border-light w-md-auto col ${styles["button-tab-view"]}`}
                          variant="light"
                          onClick={(e) => { DownloadAction(DOWNLOAD_AGREEMENT_REPORT, `ConnectwiseAgreementMappingReport_${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}.csv`, "csv", pageNo, pageSize, searchValueTable, customerId, planName, serviceId, billingPeriodId, mapFilter, companyId, agreementId) }}
                        > Download report</Button> :
                          <Button
                            className={`px-3 ml-1 btn-border-light w-md-auto col ${styles["button-tab-view"]}`}
                            variant="light"
                            disabled
                          > Downloading ...</Button>}

                        <Button
                          className={`px-3 mx-1 col ${styles["button-tab-view"]} `}
                          onClick={() => { setUploadModalOpen(true) }}
                        >Bulk Mapping</Button>
                      </div>
                    </div>


                  </div>
                </div>
              </div>

              {/* Mobile */}
              <div class="px-0 d-block d-md-none col-12">
                <div class="justify-content-md-end d-flex row">
                  <div class="col-12 mt-3">
                    {/* Filter Search Box for Desktop */}
                    <div class="justify-content-xl-end justify-content-lg-start align-items-center d-flex ">

                      {!emptyData && !serverError && !emptyFilter &&
                        <InputGroup className="search-input-box search-input-box-lg">
                          <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                            <span className="searchgrey cpointer"
                            >
                              &nbsp;
                            </span>
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Search Customer / Plan"
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                            className="search-input-box-input"
                            value={searchValueTable}
                            onChange={(e) => setSearchValueTable(e.target.value)}
                            ref={searchInputRef}
                          />
                          <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                            onClick={() => {
                              setSearchValueTable("");
                              setSearchIconFlag(!searchIconFlag);
                            }}
                          >
                            <span className="closebtn">
                              &nbsp;
                            </span>
                          </InputGroup.Text>
                        </InputGroup>}

                      <div class="mr-1">
                        {!serverError && <div>
                          {!filterFlag && !emptySearch && !loadingState && <span className="filter-black cpointer d-block" id="filterblock" onClick={() => {
                            setShowFilterBox(!showFilterBox);
                          }}></span>}
                          {filterFlag && !emptySearch && !loadingState && <span className="filter-black-active cpointer d-block" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                        </div>}
                      </div>
                    </div>

                  </div>

                  {/* Unmapped Dropdown */}
                  <div class="col-12 mt-3">

                    {!serverError &&
                      <Form.Group>
                        <NavDropdown
                          title={selectedOption}
                          id="cloud-service-dropdown"
                          onSelect={handleSelect}
                          className={`servcies-dropdown col-12 ${styles['product-plan-top-right-card']}`}
                          value={selectedOption}
                        >
                          {Object.entries(subscriptionsOptions).map(([key, value]) => (
                            <NavDropdown.Item key={key}
                              className={selectedOption === value ? "selected" : ""}
                              eventKey={key}>{value}</NavDropdown.Item>
                          ))}
                        </NavDropdown>
                      </Form.Group>}

                  </div>

                  {/* Buttons */}

                  <div class={`mt-3 ${styles["btn-wrapper-outer"]}`}>
                    <div className="btn-wrapper text-right mx-0 row justify-content-between">
                      <div className="col-6 pr-0">
                        {!confirmLoadingFlag ?
                          <Button
                            className="px-3 btn-border-light w-md-auto col-12"
                            variant="light"
                            onClick={(e) => { DownloadAction(DOWNLOAD_AGREEMENT_REPORT, `ConnectwiseAgreementMappingReport_${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}.csv`, "csv", pageNo, pageSize, searchValueTable, customerId, planName, serviceId, billingPeriodId, mapFilter, companyId, agreementId) }}
                          > Download report</Button> :
                          <Button
                            className="px-2 btn-border-light w-md-auto col-12"
                            variant="light"
                            disabled
                          > Downloading ...</Button>}
                      </div>
                      <div className="col-6 pr-0">
                        <Button
                          className="px-3"
                          onClick={() => { setUploadModalOpen(true) }}
                        >Bulk Mapping</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                !emptySearch && showFilterBox &&
                <AgreementMappingFilterBox
                  GetTableData={GetTableData} pageNo={pageNo} pageSize={pageSize} searchValueTable={searchValueTable}
                  planName={planName} setPlanName={setPlanName} planData={planData} setPageNo={setPageNo}
                  serviceId={serviceId} setServiceId={setServiceId} serviceData={serviceData}
                  billingPeriodId={billingPeriodId} setBillingPeriodId={setBillingPeriodId} billingTermData={billingTermData}
                  companyData={companyData} companyId={companyId} setCompanyId={setCompanyId}
                  customerId={customerId} setCustomerId={setCustomerId} customerData={customerData}
                  agreementData={agreementData} agreementId={agreementId} setAgreementId={setAgreementId}
                  setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} mapFilter={mapFilter} setScrollToDivTop={setScrollToDivTop}
                />
              }
            </Row>
          </div>
          <div className='p-0'>
            {emptySearch && !loadingState &&
              <div className={styles["no-data-cloud-invoice"]}>
                <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                <h2 className="mt-4 mb-3">No Data Found</h2>
              </div>
            }
            {emptyData && !loadingState &&
              <div className={styles["no-data-cloud-invoice"]}>
                <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                <h2 className="mt-4 mb-3">No Data Found</h2>
              </div>
            }
            {emptyFilter && !loadingState && !emptySearch &&
              <div className={styles["no-data-cloud-invoice"]}>
                <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                <h2 className="mt-4 mb-3">No Data Found</h2>
              </div>
            }
            {!loadingState && serverError &&
              <div className={styles["no-data-cloud-invoice"]}>
                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
              </div>
            }
            {!emptySearch && !serverError && !emptyFilter && !emptyData &&
              <div className={`table-responsive pt-3 ${styles['gws-upgrade-core']}`}>
                <LazyTable
                  d={columns}
                  table={table}
                  customStyles={customStyles}
                  loading={infinityLoading}
                  pageNo={pageNo}
                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                  styles={styles}
                  handleLazyCalls={handleLazyCalls}
                  serverErrorUser={serverError}
                  emptyUser={emptyData}
                  emptyUserFilters={emptyFilter}
                  emptyUserSearch={emptySearch}
                  setScrollToDivTop={setScrollToDivTop}
                  scrollToDivTop={scrollToDivTop}
                  conditionalRowStyles={conditionalRowStyles}
                />
              </div>
            }
            <Map setMapActionPopup={setMapActionPopup} mapActionPopup={mapActionPopup} completeRowData={completeRowData} setCompleteRowData={setCompleteRowData} syncronizationData={syncronizationData} setSyncronizationData={setSyncronizationData} setActionCompletedRefreshTable={setActionCompletedRefreshTable} Logout={Logout} />
            <Unmap setUnMapActionPopup={setUnMapActionPopup} unMapActionPopup={unMapActionPopup} completeRowData={completeRowData} setActionCompletedRefreshTable={setActionCompletedRefreshTable} Logout={Logout} />
            <Edit setEditActionPopup={setEditActionPopup} editActionPopup={editActionPopup} completeRowData={completeRowData} setCompleteRowData={setCompleteRowData} syncronizationData={syncronizationData} setSyncronizationData={setSyncronizationData} setActionCompletedRefreshTable={setActionCompletedRefreshTable} Logout={Logout} />

          </div>
        </div>
        <Row className="my-2 align-items-center">
          <Col>
            <Row>
              <Col className="justify-content-between d-flex align-items-center mb-3">
                <label className="cpointer" onClick={() => setActiveStep(1)}>
                  <span className="back">&nbsp;</span>&nbsp;<strong>Back</strong>
                </label>
              </Col>
            </Row>

          </Col>

        </Row>
      </Container>
    </>
  )
}

export default AgreementMappingConfigure